import React from 'react'
import Nav from './nav'
import SiteMetaData from "./sitemeta"
import Preloader from "./preloader"
import footerStyles from "./footer-home.module.scss"
import { Link } from 'gatsby'

import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFacebook, faTwitter, faYoutube, faInstagram, faSnapchat } from '@fortawesome/free-brands-svg-icons'

library.add(faFacebook)
library.add(faTwitter)
library.add(faYoutube)
library.add(faInstagram)
library.add(faSnapchat)

function getYear () {
  return (
    <span>{(new Date()).getFullYear()}</span>
  )
}

const year = getYear()

class Footer extends React.Component {
  render() {
    const socialIcons = [
      {
        link: 'https://www.facebook.com/rhythmicfusion/',
        icon: faFacebook,
        title: 'Facebook'
      },
      {
        link: 'https://www.snapchat.com/add/Mike%20Zuniga',
        icon: faSnapchat,
        title: 'Snapchat'
      },
      {
        link: 'https://www.instagram.com/rhythmicfusion/',
        icon: faInstagram,
        title: 'Instagram'
      },
      {
        link: 'https://www.youtube.com/channel/UCL1T7w2bdhbYNo6QDkpXkbQ',
        icon: faYoutube,
        title: 'YouTube'
      },
      {
        link: 'https://twitter.com/RhythmicFusion',
        icon: faTwitter,
        title: 'Twitter'
      },
    ]

    const renderIcons = socialIcons.map((icon, i) => (
      <a
        key={i}
        target="_blank"
        rel="noopener noreferrer"
        href={icon.link}
        title={icon.title}>
          <FontAwesomeIcon icon={icon.icon} />
      </a>
    ))
    return (
      <footer className={footerStyles.footer}>
          <div className={footerStyles.footerContent}>
          <div>
            <p style={{marginBottom: `0`}}>
              <strong style={({'textTransform':'uppercase'})}>Copyright &copy;{year} RF Dance Center. All rights reserved. </strong><br /> 
              1517 N. Main st, 
              Santa Ana, CA 92701 |
              (949) 689-1835
            </p>
          </div>
          <div className={footerStyles.social}>
        
            <span className={footerStyles.socialIcons}>
              {renderIcons}
            </span>
            <a href="https://app.termly.io/document/privacy-policy/01de1bb4-2429-4ab3-a5f8-e2116df7eae5" target="_blank" rel="noopener noreferrer">Privacy policy</a>
            
            <Link to="/terms-of-use">Terms of use</Link>
            
            <a href="https://goo.gl/maps/n5k6pfzF84x" rel="noopener noreferrer" target="_blank">
              Review us on Google
            </a>
          </div>
          </div>
      </footer>
    )
  } 
}
const Layout = (props) => (
  <div>
      <Preloader 
        type={props.type || `none`} 
        color={props.color || `white`} 
        height={props.height || `50%`} 
        width={props.width || `50%`} 
      />
      <SiteMetaData 
          title={props.title}
          pathname={props.pathname}
          robots={props.robots}
          description={props.description}/>
      {(props.noHero ? <Nav noHero={props.noHero}/> : '')}
      <div>
        {props.children}
      </div>
      <Footer />
  </div>
)

export default Layout