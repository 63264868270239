import React from "react"
import styles from "./halfHero.module.scss"
import Button from "../components/button"
import promo from "../video/bg-online.mp4"
import promo2 from "../video/bg-promo.mp4"
import logo from "../images/logo.svg"

class Video extends React.Component {
  constructor(props) {
    super(props)
    this.videoRef = React.createRef()
  }

  state = {
    prevPlaying: this.props.playing,
    ...this.props
  }
  componentDidUpdate() {
    // console.log(this.state)
    if (this.props.playing) {
      this.videoRef.current.play()
    } else {
      this.videoRef.current.pause()
    }
  }

  static getDerivedStateFromProps(props, state) {
    if (props.playing !== state.prevPlaying) {
      return {
        prevPlaying: props.playing
      }
    }
    return null;
  }

  render () {
    return (
      <video
        ref={this.videoRef}
        loop="loop"
        className={`${styles.video} ${(this.props.playing ? `` : styles.videoHidden)}`}
        muted="muted" 
        src={this.state.src}
        >
          <track />
      </video>
    )
  }
}

class HalfSection extends React.Component {
  constructor(props) {
    super(props)
    this.children = this.props.children
    this.state = {
      playing: false,
      window: 0,
      ...props
    }
    this.halfRef = React.createRef()
  }

  updateVideo(bool) {
    this.setState(prevState => ({
      ...prevState,
      playing: bool
    }))
  }

  componentDidMount() {
    try {
      this.setState(prevState => {
        console.log(window.innerWidth)
        return {
          ...prevState,
          window: window.innerWidth
        }
      })
    } catch(error) {
      console.log(error)
    }
  }
  
  render() {
    if (this.state.window > 768) {
      console.log('video should load', this.state.window)
      return (
        <div 
          ref={this.halfRef} 
          className={`column ${styles[this.props.shade]} ${styles.content} ${(this.state.playing ? styles.highlighted: styles.backburner)}`}
          onMouseEnter={() => {this.updateVideo(true)}}
          onMouseLeave={() => {this.updateVideo(false)}}
          >
          {this.children}
          <div className={styles.videoWrapper}>
            <Video 
              playing={this.state.playing} 
              src={this.state.video}
            />
          </div>
        </div>
      )
    } else {
      console.log('video should not load', this.state.window)
      return (
        <div 
          ref={this.halfRef} 
          className={`column ${styles[this.props.shade]} ${styles.content}`}
          >
          {this.children}
        </div>
      )
    }
  }
}

export default ({ children }) => (
  <div id="banner" className={styles.halfHeroWrapper}>
    <div className={styles.halfHero}>
      <div className={`columns ${styles.columns}`}>
        <HalfSection 
          video={promo2}
          shade="dark">
          <div className={`${styles.container} ${styles.textContent}`}>
            <img className={styles.logo} src={logo} alt="RF Dance Center"/>
            <h3 className={styles.title}><span>RF Dance</span> Center</h3>
            <p>Join an in-person class at the RF Dance Studio in the heart of Orange County, CA.</p>
            <p className="is-hidden-touch">This is the hottest location in Southern California to learn salsa, bachata, cumbia, and other latin dance styles.</p>
            <p><Button styling="isDanceCenter" type="link" text="RF Dance Center" href="/center"/></p>
          </div>
          </HalfSection>
          <HalfSection video={promo} shade="light">
            <div className={`${styles.container} ${styles.textContent}`}>
             <img className={styles.logo}  src={logo} alt="RF Dance Center"/>
              <h3 className={`${styles.titleOnline} ${styles.title}`}>Online <span>RF Dance</span></h3>
              <p>Join RF Dance from anywhere, online.</p>
              <p className="is-hidden-touch">Get exclusive content at every level from Orange County's prodigy dance company. Hone your technique from on-demand video lessons, or join a live class.</p>
              <p><Button type="a" styling="isOnline" text="Online RF Dance" href="https://online.rfdance.com"/></p>
            </div>
          </HalfSection>
        </div>
      </div>
    </div>
)