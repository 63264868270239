import React from "react"
import Nav from "../components/nav"
import homeStyles from "../components/index.module.scss"
import rfLogo from "../images/rf-logo.svg"
import Layout from "../components/layout-home"
import Image from "../components/image"
import { Link, navigate } from "gatsby"
import HalfHero from "../components/halfHero"

const HeroFull = () => (
  <div className={homeStyles.hero}>
    {/* <div className={homeStyles.heroHead}>
    <Nav />
    </div> */}
    {/* <div className={homeStyles.heroBody}>
      <div className={homeStyles.heroContent}>
        <img src={rfLogo} alt="RF Dance" />
      </div>
    </div> */}
    <HalfHero/>
  </div>
)

export default () => {
  // get browser width, redirect if mobile
  // if (typeof document !== `undefined` ) {
  //   const browserWidth = Math.max(
  //     document.body.scrollWidth,
  //     document.documentElement.scrollWidth,
  //     document.body.offsetWidth,
  //     document.documentElement.offsetWidth,
  //     document.documentElement.clientWidth
  //   );
  //   if (browserWidth < 769) {
  //     navigate('/center');
  //   }
  // }


  return (
  <Layout
    title="RF Dance | Salsa &amp; Bachata in Orange County, California"
    description="Orange County's latin dance company. We offer classes for bachata, and salsa, as well as teams, workshops, private lessons. Contact us for quinceaneras, wedding choreography, and more."
    pathname="">
    <HeroFull />
  <style jsx="jsx">{`
    footer {
      display: none;
    }
  `}</style>
  </Layout>
  )
}
